$primary-dark: rgb(0,40,86);
$primary-light: rgb(228,228,228);
$primary-lighter: rgb(244 244 244);
$primary-alt: rgb(230,71,131);

$secondary-dark: rgb(111,114,113);
$secondary-alt: rgb(140,183,201);

$white: rgb(255,255,255);
$black: rgb(0,0,0);

// colour picker options
$grey: rgb(103,121,139);
$blue: rgb(39,55,128);
$purple: rgb(151,30,128);
$green: rgb(61,164,54);
$red: rgb(205,22,28);
$orange: rgb(209,95,22);
$yellow: rgb(250,198,70);
