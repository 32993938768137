.icon {
    cursor: pointer;
}

.icon-xs {
    height: 1rem;
}

.icon-sm {
    height: 1.5rem;
}

.icon-md {
    height: 2rem;
}

.icon-lg {
    height: 3rem;
}

.fill-primary-alt {
    fill: $primary-alt;

    &:hover {
        fill: $primary-dark;
    }
}

.fill-dark {
    fill: $primary-dark;

    &:hover {
        fill: $primary-alt;
    }
}
.fill-white {
    fill: $white;
}

.stroke-dark {
    stroke: $primary-dark;

    &:hover {
        stroke: $primary-alt;
    }
}

.stroke-light {
    stroke: $white;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    fill: none;
    stroke-width: 2;
}

.stroke-primary-alt{
    stroke:$primary-alt;    
}

.navbar-icon {
    height: 28px;

    .stroke-color {
        stroke: $white;
    }

    .fill-color {
        fill: $white;
    }
}


.nav-link {
    .nav-dropdown-icon {
        .stroke-color {
            stroke: $primary-alt;
        }

        .fill-color {
            fill: $primary-alt;
        }
    }

    &.alt {
        .nav-dropdown-icon {
            .stroke-color {
                stroke: $white;
            }

            .fill-color {
                fill: $white;
            }
        }
    }
}

.collapse-button {
    .mobile-nav-dropdown-icon {
        transform: rotate(-90deg);
        transition: 0.4s ease-in-out;

        .fill-color {
            fill: $white;
        }
    }

    &[aria-expanded="false"] {
        .mobile-nav-dropdown-icon {
            transform: rotate(90deg);
        }
    }
}

.icon-bar {
    .stroke-color {
        stroke: $primary-dark;
    }

    .fill-color {
        fill: $primary-dark;
    }

    &:hover {
        .stroke-color {
            stroke: $primary-alt;
        }

        .fill-color {
            fill: $primary-alt;
        }
    }
}

.icon-dark {
    .stroke-color {
        stroke: $primary-dark;
    }

    .fill-color {
        fill: $primary-dark;
    }

    &:hover {
        .stroke-color {
            stroke: $primary-alt;
        }

        .fill-color {
            fill: $primary-alt;
        }
    }
}

.icon-light {
    .stroke-color {
        stroke: $primary-light;
    }

    .fill-color {
        fill: $primary-light;
    }

    &:hover {
        .stroke-color {
            stroke: $primary-alt;
        }

        .fill-color {
            fill: $primary-alt;
        }
    }
}

.tick-icon{
    fill:$primary-alt;
}

.favourites-icon {
    .fill-color {
        fill: none;
    }

    .stroke-color {
        stroke: $primary-alt;
    }

    &:hover {
        .fill-color {
            fill: $primary-alt;
        }

        .stroke-color {
            stroke: $primary-alt;
        }
    }
}

.favouriteBoard-add {
    .expand-icon {
        transform: rotate(45deg);

        
        .fill-color {
            fill: $primary-alt;
        }

        &:hover {
            .fill-color {
                fill: $primary-dark;
            }
        }

        &.selected {
            transform: none;
            
                .fill-color {
                    fill: $primary-dark;
                }            
        }
    }

    p {
        user-select: none;
    }
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 25px solid $secondary-alt;
}

.cart-count {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 46.5%;
    font-weight: 600;
    font-size: .75rem;
    color: $primary-dark;
}

.icon-download, .icon-print, .icon-share {
    path {
        transition: .5s ease;
        fill: $primary-alt;
    }

    &:hover {
        path {
            fill: $primary-dark;
        }
    }
}

.icon-product {
    path {
        transition: .5s ease;
        fill: transparent;
        stroke: $primary-alt;
        stroke-width: 1;
    }

    &:hover {
        path {
            fill: $primary-alt;
        }
    }
}

.spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    width: 2rem;
    height: 2rem;

    & .path {
        stroke: $primary-alt;
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }
}

.icon-zoom {
    path {
        fill: $primary-dark;
    }

    &:hover {
        path {
            fill: $primary-dark;
        }
    }
}