#innerBanner {
    position: relative;

    .banner-overlay {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        h1 {
            color: $white;
            text-transform: uppercase;
            margin-bottom: 0;
            font-size: 3rem;
        }
    }
}

.thin-overlay-center {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.image-overlay-container {
    max-height: 100%;
    max-width: 100%;
    position: relative;
}

.overlay-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.overlay-bottom {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.overlay-left {
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -25%);
}

.overlay-right {
    position: absolute;
    top: 50%;
    right: 25%;
    transform: translate(50%, -25%);
}

.overlay-top-left {
    position: absolute;
    top: 5%;
    left: 10%;
    transform: translate(-50%, -25%);
}

.overlay-top-right {
    position: absolute;
    top: 5%;
    right: 1%;
    transform: translate(-50%, -25%);
}

.overlay-bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
}

.overlay-bottom-center {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.iframe-container {
    position: relative;
    padding-bottom: 42.85%; /* 21:9 */
    height: 0;

    @include media-breakpoint-down(md) {
        display: none;
    }
}

.iframe-container-mobile {
    position: relative;
    padding-bottom: 75%; /* 4:3 */
    height: 0;

    @include media-breakpoint-up(md){
        display:none;
    }
}

.iframe-responsive {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.img-brand {
    max-height: 3rem;
    aspect-ratio: auto;
}

.brand-logo {
    max-height: 5rem;
}
