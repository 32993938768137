footer {
    color: $white;

    a {
        color: $white;
        text-decoration: none;

        &:hover {
            color: $white;
            text-decoration: underline;
            text-underline-offset: .15rem;
        }
    }

    .form-control {
        border-bottom: 1px solid $white;
        color: $white;
    }

    input::placeholder {
        color: $white !important;
    }

    .input-group-text {
        color: $white;
    }
}
