.bg-gradient-primary {
    background: $primary-dark;
    background: linear-gradient(90deg, rgba($primary-dark, 1) 0%, rgba($primary-dark, 1) 50%, rgba($primary-alt, 1) 100%);
}

.bg-primary-light {
    background: $primary-light;
}

.bg-primary-lighter {
    background: $primary-lighter;
}

.bg-primary-dark{
    background: $primary-dark;
}

.bg-primary-alt{
    background:$primary-alt;
}

.bg-secondary-alt{
    background: $secondary-alt;
}

.bg-secondary-alt-light {
    background: rgba($secondary-alt, .2) !important;
}

.bg-secondary-light {
    background: rgba($secondary-dark, .3);
}

.bg-white {
    background-color: $white;
}

.bg-black {
    background-color: $black;
}

.bg-grey {
    background-color: $grey;
}

.bg-blue {
    background-color: $blue;
}

.bg-purple {
    background-color: $purple;
}

.bg-green {
    background-color: $green;
}

.bg-red {
    background-color: $red;
}

.bg-orange {
    background-color: $orange;
}

.bg-yellow {
    background-color: $yellow;
}

.bg-warning {
    background-color: rgba($red, 0.2) !important;
    border: 2px solid $red;
}



