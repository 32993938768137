#searchDropdown {
    position: fixed;
    top: 6.5rem;
    background-color: $white;
    z-index: 9999;
    width: 100%;

    @include media-breakpoint-down(md) {
        top: 9.75rem;
    }

    @include media-breakpoint-down(md) {
        .search-results {
            height: 600px;
            overflow-y: scroll;
            width: 100%;
        }
    }
}


.nav-background-overlay, .dropdown-background-overlay, .search-background-overlay {
    main {
        background-color: $grey;
        opacity: 0.3;
    }

    footer {
        background-color: $grey;
        opacity: 0.3;
    }
}