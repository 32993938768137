.show-favourites {
    display: block;
    scroll-behavior: smooth;
    
}



@keyframes rotate {

    100% {
        transform: rotate(360deg);
    }
}



@keyframes dash {

    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}