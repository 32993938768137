.colour-item {
    cursor: pointer;

    &.is-hidden {
        display: none;
    }

    figure {
        .figure-caption {
            text-transform: capitalize;
            text-align: center;
            color: $primary-dark;
            font-size: .825rem;
        }
    }
}

.dropdown-filter {
    .dropdown-toggle {
        background-color: $primary-alt;
        color: $white;
        text-align: start;
        font-weight: $font-regular;
        transition: .5s ease all;

        &:after {
            border: none;
            margin: 0;
            width: 1rem;
            height: 1rem;
            background-size: 1rem;
            margin-top: .25rem;
            float: right;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' id='iconExpandAccordion' x='0' y='0' version='1.1' viewBox='0 0 32 32'%3E%3Cpath d='M13.3 0v32h5.4V0h-5.4z' style='fill:#ffffff'/%3E%3Cpath d='M32 13.3H0v5.4h32v-5.4z' style='fill:#ffffff'/%3E%3C/svg%3E");
        }

        &.show {
            &:after {
                transform: rotate(45deg);
            }
        }
    }

    .dropdown-menu {
        border: 1px solid $primary-light;
        border-radius: 0;
        top: -2px;
        border-top: none;
        padding: .5rem;



        .colour-reset {
            background-color: $primary-alt;
            color: $white;
            justify-content: center;
            font-weight: $font-bold;
            font-size: .825rem;
            padding: 0.25rem;
            margin-block: 0.125rem;
            cursor: pointer;
            width: 100%;

            @include media-breakpoint-up(sm) {
                width: 50%;
            }

            @include media-breakpoint-up(xl) {
                width: 25%;
            }
        }

        .colour-filter {
            font-size: .825rem;
            padding: .25rem;
            margin-block: .125rem;
            width: 100%;
            color: $primary-dark;

            @include media-breakpoint-up(sm) {
                width: 50%;
            }

            @include media-breakpoint-up(xl) {
                width: 25%;
            }

            &:hover {
                text-decoration: underline;
                text-underline-offset: 2px;
                cursor: pointer;
            }

            &.active {
                background-color: rgba($secondary-alt, .3);

                &:has(#filterWHI) {
                    span {
                        border: 1px solid transparent;
                    }
                }
            }

            span {
                display: inline-block;
                height: 1.5rem;
                width: 1.5rem;
                margin-inline-end: .5rem;
            }

            &:has(#filterWHI) {
                span {
                    border: 1px solid $black;
                }
            }
        }
    }
}