#icon-tiles {
    img {
        background-color: rgba(($grey), 0.3);
    }

    .card-header {
        color: $primary-dark;
        background-color: $primary-light;

        p {
            margin-bottom: 0;
        }
    }

    .card-body {
        background-color: $secondary-alt;
    }

    .card {
        border-radius: 0;
    }

    .tile-cards {
        overflow-x: auto;
    }

    .tile-cards .item:first-child {
        margin-left: auto;
    }

    .tile-cards .item:last-child {
        margin-right: auto;
    }
    //scollbar
    /* width */
    ::-webkit-scrollbar {
        width: 20px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: $primary-light;
        margin: 0 1rem 0 1rem;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $primary-dark;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: $primary-alt;
    }
}

#image-tiles {
    margin: auto;

    .card-body {
        background-color: $primary-dark;
        color: $primary-light;
    }

    .card {
        border-radius: 0;
    }

    .tile-cards {
        overflow-x: auto;
    }

    .tile-cards .item:first-child {
        margin-left: auto;
    }

    .tile-cards .item:last-child {
        margin-right: auto;
    }
    //scrollbar
    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: $primary-light;
        margin: 0 1rem 0 1rem;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $primary-dark;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: $primary-alt;
    }
}

.card-columns {
    column-count: 1;

    .card {
        break-inside: avoid-column;
    }

    @include media-breakpoint-up(md) {
        column-count: 2;
    }

    @include media-breakpoint-up(lg) {
        column-count: 4;
    }

    @include media-breakpoint-up(xl) {
        column-count: 6;
    }
}

.image-panel {
    .card-columns {
        column-count: 2;
    }
}

.account-downloads {
    .card-img-top {
        width: 100%;
        height: 30vh;
        object-fit: contain;
        border-radius: 0 !important;

        &.image-icon {
           height:100%;
        }
    }

    .icon-select-container {
        border: 1px solid $primary-dark;
        color: $white;
        border-radius: 0;

        &:hover {
            color: $white;
        }
    }

    .card-body {
        background-color: $primary-alt;
        color: $white;

        p {
            margin: 0;
            font-size: 1.125rem;
            text-transform: uppercase;
            font-weight: $font-bold;
        }
    }
}

.colour-card {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    img {
        border: 1px solid $black;
    }
}

.card-container {
    .card {
        border-radius: 0;
        color: $primary-dark;

        .card-img-top {
            border-radius: 0;
        }
    }
}

.marketing-hub-blocks {
    .card {
        border-radius: 0;
        border: 1px solid $primary-dark;
    }

    .card-img-top {
        width: 100%;
        height: 25vh;
        object-fit: contain;
        border-radius: 0 !important;

        &.image-icon {
            height: 100%;
            object-fit: fill;
        }
    }

    .card-img {
        border-radius: 0;
    }


    .icon-select-container {
        //border: 1px solid $primary-dark;
        color: $white;

        .card-img-overlay {
            display: none;
            background-color: rgba($primary-alt, 0.8);
            height: 86%;
            border-radius: 0;

            @include media-breakpoint-down(lg) {
                display: block;
                background-color: rgba($primary-light, 0.8);


                .card-text {
                    font-size: 1rem;
                    color: $primary-dark;
                }

                &.no-overlay-sm {
                    display: none;
                }
            }

            @include media-breakpoint-only(md) {
                height: 86%;

                .card-text {
                    font-size: 0.8rem;
                }
            }

            @include media-breakpoint-down(md) {
                height: 84%;
            }
        }

        .card {
            &.wide-image {
                .card-img-overlay {
                    @include media-breakpoint-down(md) {
                        height: 72% !important;
                    }
                }
            }
        }

        &:hover {
            color: $white;

            .card-img-overlay {
                display: block;
                -webkit-transition: all 2s linear;
                -moz-transition: all 2s linear;
                -o-transition: all 2s linear;
                transition: all 2s linear;

                @include media-breakpoint-down(lg) {
                    &.no-overlay-sm {
                        display: none;
                    }
                }
            }
        }
    }

    .card-body {
        background-color: $primary-alt;
        color: $white;
        margin: 1rem,0,1rem,0;

        .card-text {
            margin: 1rem,0,1rem,0;
        }

        p {
            margin: 0;
        }
    }
}

.grid-image-square-container {
    .grid-image-square {
        border: 1px solid $primary-dark;
        aspect-ratio: 1 / 1;
        width: 100%;
        position: relative;
        padding: 1rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    img {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
   
}
