#scroll-banner{
    header {
        &.is-hidden {
            margin-top: -3.75rem;

            .header-content {
                transform: translateY(-100%);
            }

            .header-elements {
                opacity: 1;
            }
        }
    }
}

header {
    &.is-hidden {
        .navbarHeader-ShowHide {
            opacity: 1;
        }
    }

    .navbarHeader-ShowHide {
        opacity: 0;
        transition: .5s;

        //@include media-breakpoint-down(md) {
        //    opacity: 1;
        //}
    }
}

.sub-header-banner {
    p {
        color: $primary-dark;
        font-size: 0.35rem;

        @include media-breakpoint-up(lg){
            font-size: .725rem;
            line-height: 1.2;
        }
    }

    .icon {
        border: 2px solid $primary-alt;
    }
}

.countdown-banner{
    background-color: $primary-alt;

    p{
        margin:0;
    }
}

.header-dark {
    background-color: $primary-dark;
    color: $white;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.header-elements {
    .input-group {
        background-color: $white;
        border-radius: 100vmax;
        padding: 0 1rem;
    }
}

.header-content {
    background-color: white;

    p {
        font-size: .925rem;
        line-height: 1.2;
    }
}