.icon-tooltip {
    display: none;
    background-color: $white;
    border: 2px solid $primary-alt;
    position: absolute;   
}

.inBasket-icon{
    position:relative;
}

.inBasket-icon:hover + .icon-tooltip {
    display: flex;
    color: $primary-alt;
    z-index: 10;
}
