.splide__slide {
    img {
        width: 100%;
        height: auto;
    }

    .carousel-text-container {
        top: 220px;
        color: $white;
        background-color: rgba($primary-dark, .8);
        height:100%;

    }

    p {
        opacity: 1;

        @include media-breakpoint-down(md){
            font-size: 1rem;
        }
    }


    a:hover {
        text-decoration: none;
    }
}

.splide__arrow {
    opacity: 1;
    
    &--prev {
        left: 0;
    }

    &--next {
        right: 0;
    }

    &:disabled {
        opacity: 0;
    }
}

.splide__pagination {
    bottom: 2rem;
}

.splide__pagination__page {
    border-radius: 0;
    height: 1.5rem;
    width: 1.5rem;
    opacity: 1;
    background-color: $secondary-alt;

    &.is-active {
        background-color: $primary-alt;
        transform: none;
        border: .375rem solid $secondary-alt;
    }
}

#alternativeProductsCarousel, #featuredCarousel {
    .splide__track {
        margin: 0 3rem;
    }
}