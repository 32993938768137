$heading-font-family: montserrat, sans-serif;
$body-font-family: Arial, Helvetica Neue, Helvetica, sans-serif;

$font-bold: 700;
$font-regular: 400;
$font-light: 300;

body {
    font-family: $heading-font-family;
    font-weight: $font-light;
    color: $primary-dark;
}

.title {
    font-family: $heading-font-family;
    font-weight: $font-bold;
}

h1 {
    font-family: $heading-font-family;
    font-weight: $font-regular;

    &.large {
        font-size: 1.125rem;
        font-weight: 500;

        @include media-breakpoint-down(md) {
            font-size: 1rem;
        }
    }
}

h2 {
    font-family: $heading-font-family;
    font-weight: $font-regular;
}

h3 {
    font-size: 1.125rem;
}

b, strong {
    font-weight: $font-bold;
}

.fw-bold {
    font-weight: $font-bold !important;
}

a {
    color: $primary-dark;
    text-decoration: none;
    transition: .5s ease all;

    &:hover {
        text-decoration: underline;
        text-underline-offset: .15rem;
        color: $primary-dark;
    }

    &.alt {
        color: $primary-alt;
    }
}

p, li, span {
    font-size: .875rem;

    &.large {
        font-size: 1.125rem;
        font-weight: 500;

        @include media-breakpoint-down(md) {
            font-size: 1rem;
        }
    }

    &.alt {
        color: $primary-alt;
        font-size: 1.125rem;
        font-weight: $font-bold;
    }
}

hr {
    opacity: 1;
    border-color: $primary-dark;
}

@include media-breakpoint-down(md) {
    h1 {
        font-size: 1.325rem;
    }

    p {
        font-size: .875rem;
    }

    li {
        font-size: .875rem;
    }

    ::placeholder {
        font-size: .875rem;
    }

    option, .form-select {
        font-size: .875rem;
    }

    input[type='date'] {
        font-size: .875rem;
    }

    footer {
        .title {
            font-size: 1rem;
            font-weight: $font-regular;
        }
    }

    table {
        font-size: .875rem;
    }
}

.text-main {
    color: $primary-dark;
}

.text-light {
    color: $primary-light;
}

.text-white {
    color: $white;
}

.text-alt{
    color:$primary-alt;
}

.customer-testimonial {
    background-color: $primary-dark;
    display: flex;
    align-items: center;
}

.image-text-block {
    display: flex;
    align-items: center;
}

.text-small, .small{
    font-size: .75rem;
}

.text-med {
    font-size: 0.8rem;
}

.image-overlay-dark-bg {
    .image-overlay-text {

        h2 {
            color: $primary-light;
        }

        p {
            color: $primary-light;
        }
    }
}

.image-overlay-light-bg {

    .image-overlay-text {
        h2 {
            color: $primary-dark;
        }

        p {
            color: $primary-dark;
        }
    }
}

.policy-text-block{
    column-count: 2;
}

.inherit{
    color: inherit;
    text-decoration: none;

    &:hover{
        text-decoration: none;
        font-weight: bold;
        color: $primary-dark;
    }
}

.lh-normal{
    line-height: normal;
}