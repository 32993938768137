#filterResults, #alternativeProductsCarousel, .overlay-information {
    .filter-item {
        position: relative;
        margin-bottom: 1.5rem;

        .btn-favourite {
            position: absolute;
            top: 0;
            right: 0;
        }

        &__overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            color: $white;
            background-color: rgba($primary-dark, .8);

            .text-small {
                font-size: 0.8rem;
            }

            .text-large {
                font-size: 1.5rem;
            }
        }

        &__title {
            font-size: 1rem;
            text-align: center;
            padding: 1rem;
            margin-bottom: 0;


            @include media-breakpoint-up(md) {
                font-size: 1.2rem;
            }
        }

        &__content {
            display: none;
            padding: 0 1rem 1rem;
            text-align: center;

            p {
                margin-bottom: .5rem;
            }
        }

        &__description {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        .btn-overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 1rem;
            background-color: $primary-alt;
            font-size: 1rem;
            font-weight: bold;

            p {
                font-size: 1rem;

                @include media-breakpoint-up(md) {
                    font-size: 1.2rem;
                }
            }
        }
    }

    a:hover {
        .filter-item {

            &__title {
                color: $primary-alt;

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            &__overlay {
                background-color: rgba($white,.9);
                color: $primary-dark;
                height: 100%;

                @include media-breakpoint-down(md) {
                    background-color: transparent;
                }
            }

            &__content {
                display: block;
            }
        }
    }

    .button-remove {
        position: absolute;
        top: 0;
        right: 0;
    }

    .button-quickOrder {
        position: absolute;
        top: 0;
        left: 0;
    }
}
