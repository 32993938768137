@import "../../node_modules/bootstrap/scss/bootstrap";

@import "base/colours";
@import "base/typography";
@import "base/animations";

@import "components/accordion";
@import "components/backgrounds";
@import "components/breadcrumb";
@import "components/buttons";
@import "components/carousel";
@import "components/filters";
@import "components/icons";
@import "components/inputs";
@import "components/lists";
@import "components/media";
@import "components/modal";
@import "components/padding";
@import "components/panel";
@import "components/tables";
@import "components/cards";
@import "components/search";
@import "components/tooltips";

@import "layout/header";
@import "layout/footer";
@import "layout/navigation";

@import "pages/product-filter";
@import "pages/quick-order";

html {
    border: none !important;
}