.accordion-item {
    border-color: $primary-dark;

    &:first-child {
        border-top: 1px solid $primary-dark !important;
    }

    &:last-child {
        border-bottom: 1px solid $primary-dark !important;
    }

    .accordion-body {
        color: $primary-dark;
    }

    .accordion-button {
        transition: .5s ease all;
        font-size: 1.125rem;
        color: $primary-dark !important;

        @include media-breakpoint-down(md) {
            font-size: 1rem;
        }

        &:after {
            width: 2rem;
            height: 2rem;
            background-size: 2rem;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' id='iconExpandAccordion' x='0' y='0' version='1.1' viewBox='0 0 32 32'%3E%3Cpath d='M13.3 0v32h5.4V0h-5.4z' style='fill:#e64783'/%3E%3Cpath d='M32 13.3H0v5.4h32v-5.4z' style='fill:#e64783'/%3E%3C/svg%3E");
        }

        &:not(.collapsed) {
            background-color: $primary-dark;
            color: $white !important;

            &:after {
                transform: rotate(45deg);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' id='iconExpandAccordion' x='0' y='0' version='1.1' viewBox='0 0 32 32'%3E%3Cpath d='M13.3 0v32h5.4V0h-5.4z' style='fill:#ffffff'/%3E%3Cpath d='M32 13.3H0v5.4h32v-5.4z' style='fill:#ffffff'/%3E%3C/svg%3E");
           
            }

            &:hover {
                background-color: $primary-dark;
            }
        }



        &:hover {
            background-color: $primary-alt;
            color: $white !important;

            &:after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' id='iconExpandAccordion' x='0' y='0' version='1.1' viewBox='0 0 32 32'%3E%3Cpath d='M13.3 0v32h5.4V0h-5.4z' style='fill:#ffffff'/%3E%3Cpath d='M32 13.3H0v5.4h32v-5.4z' style='fill:#ffffff'/%3E%3C/svg%3E");
            }
        }

        &:focus {
            box-shadow: none;
            border-top: 1px solid transparent;
        }
    }
}

#mobileFooterAccordian {
    .accordion-item {
        border-bottom: 1px solid $white;
        border-left: none;
        border-right: none;

        &:first-child {
            border-top: 1px solid $white !important;
        }

        &:last-child {
            border-bottom: 1px solid $white !important;
        }        

        .accordion-body {
            background: $primary-dark;
            background: linear-gradient(90deg, rgba($primary-dark, 1) 0%, rgba($primary-dark, 1) 50%, rgba($primary-alt, 1) 100%);
            color: $white;
        }       
    }

    .accordion-button {
        border-radius: 0;
        color: $white !important;
        background: $primary-dark;
        background: linear-gradient(90deg, rgba($primary-dark, 1) 0%, rgba($primary-dark, 1) 50%, rgba($primary-alt, 1) 100%);
        font-size:.875rem;
    }
}

#filterAccordion {
    .accordion-body {
        background-color: rgba($primary-light, .7);
    }
}