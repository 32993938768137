body {
    padding-top: 2.75rem;

    @include media-breakpoint-up(sm) {
        padding-top: 6.75rem;
    }
}

.form-margin {
    margin-bottom: 0.25rem;

    @include media-breakpoint-up(md) {
        margin-bottom: 1rem;
    }
}
