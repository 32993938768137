.navbar {
    padding: 0;
}

.nav-header {
    padding-bottom: 0;
    font-size: .875rem;
    color: $white;

    @include media-breakpoint-up(sm) {
        min-height: 4rem;
        height: 4rem;

        .btn-icon {
            min-height: 4rem;
        }
    }

    .form-control {
        font-size: .825rem;
        color: $primary-dark;

        &:focus {
            background-color: transparent;
        }
    }

    input::placeholder {
        color: $primary-dark !important;
    }

    .input-group-text {
        color: $primary-dark;
    }
}

.navbar-toggler {
    border-radius: 0;
    background-color: $primary-alt;
    padding: .25rem .375rem;
    width:2.7rem;
    height: 2.375rem;
    border: .0625rem solid $white;
}

.navbar-collapse {
    background-color: $white;
    border: none;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px;

    @include media-breakpoint-down(md) {
        font-size: 0.7rem;
        padding: 1rem 0;
    }

    @include media-breakpoint-up(md) {
        padding: 1rem;
    }
}

#mainNav {
    .nav-item {
        .nav-link {
            color: $primary-dark;
        }
    }

    .tab-pane {
        max-height: calc(100vh - 7.75rem);

        @include media-breakpoint-down(xl) {
            overflow-y: scroll;
        }

        li {
            font-size: .825rem;
            margin-bottom: .125rem;
        }
    }

    &.navbar-collapse {
        @include media-breakpoint-down(md) {
            width: 100%;
            height: 100%;
            position: fixed;
            overflow-y: scroll;
            top: 2.875rem;
            height: calc(100% - 2.875rem);
        }
    }

    .tab-panel {
        @include media-breakpoint-down(md) {
            display: none;

            .level-0 {
                z-index: 1600;
            }

            .level-1 {
                z-index: 1700;
            }
        }

        &.open {
            display: block;
        }
    }
}

.navbar-toggler span {
    display: block;
    background-color: $white;
    height: .125rem;
    width: 2rem;
    margin-top: .375rem;
    margin-bottom: .375rem;
    position: relative;
    left: 0;
    opacity: 1;
    transition: all 0.35s ease-out;
    transform-origin: center left;
}

.navbar-toggler span:nth-child(1) {
    transform: translate(0%, 0%) rotate(0deg);
}

.navbar-toggler span:nth-child(2) {
    opacity: 1;
    width: 1.5rem;
}

.navbar-toggler span:nth-child(3) {
    width: 1.25rem;
    transform: translate(0%, 0%) rotate(0deg);
}

.navbar-toggler span:nth-child(1) {
    margin-top: .25rem;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
    transform: translate(18%, -33%) rotate(45deg);
    width: 1.575rem;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
    opacity: 0;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
    transform: translate(18%, 33%) rotate(-45deg);
    width: 1.575rem;
}

#top-level {
    .nav-item {
        border-bottom: 1px solid $primary-dark;
        text-align: center;
        font-size: 1.125rem;
        text-transform: uppercase;
        font-weight: $font-regular;
        margin-bottom: 0;

        &:last-child {
            @include media-breakpoint-up(md) {
                border-bottom: none;
            }
        }

        .nav-link {
            border-radius: 0;
            border: none;

            &:hover {
                background-color: $primary-dark;
                color: $white;
                font-weight: $font-bold;

                .nav-dropdown-icon {
                    .stroke-color {
                        stroke: $white;
                    }

                    .fill-color {
                        fill: $white;
                    }
                }
            }

            &.active {
                background-color: $primary-dark;
                color: $white;
                font-weight: $font-bold;

                .nav-dropdown-icon {

                    .stroke-color {
                        stroke: $white;
                    }

                    .fill-color {
                        fill: $white;
                    }

                    .minus-stroke {
                        stroke: transparent !important;
                        fill: transparent !important;
                    }
                }
            }

            &.alt {
                color: $primary-dark;

                &:hover, &.active {
                    background-color: $primary-alt;
                    color: $white;
                    font-weight: $font-bold;

                    .nav-dropdown-icon {
                        .stroke-color {
                            stroke: $white;
                        }

                        .fill-color {
                            fill: $white;
                        }
                    }
                }
            }
        }
    }
}

#tabPaneMobileNav {
    @include media-breakpoint-down(md) {
        .tab-pane {
            &.active {
                overflow-y: scroll;
            }
        }
    }
}

.list-title {
    font-weight: $font-bold;
    color: $primary-alt;
    margin-bottom: .5rem;
    text-transform: uppercase;
}

#accountNav, #noAccountNav {
    position: relative;
    width: 28%;
    left: calc(100% - 29.7%);
    top: 0;

    @include media-breakpoint-only(xs) {
        left: 0;
        width: 100%;
    }

    @include media-breakpoint-only(md) {
        left: calc(100% - 35%);
        width: 35%;
    }

    @include media-breakpoint-only(lg) {
        left: calc(100% - 40%);
        width: 40%;
    }

    @include media-breakpoint-only(xl) {
        left: calc(100% - 30%);
        width: 30%;
    }

    @media (min-width: 2500px) {
        width: 30%;
        left: calc(100% - 29.6%);
    }
}

.navbar-dropdown-menu {
    button[aria-expanded="true"] {
        color: $primary-dark;
        background-color: $white;
        border-start-end-radius: 10px;
        border-start-start-radius: 10px;

        col {
            background-color: $white;
        }

        .navbar-icon {
            .stroke-color {
                stroke: $primary-dark;
            }

            .fill-color {
                fill: $primary-dark;
            }
        }
    }
}

#checkoutNavbar {
    .nav-pills {
        background-color: $primary-dark;

        .nav-item {
            justify-content: center;
            display: flex;
            padding: 0;
            width: 25%;

            .nav-link {
                width: 100%;
                font-size: 1.125rem;
                border-radius: 0;
                background-color: $primary-dark;
                color: $white;

                &.active {
                    color: $white !important;
                    background-color: $secondary-alt !important;
                }

                &:hover {
                    color: none !important;
                }

                @include media-breakpoint-down(md) {
                    font-size: 0.8rem !important;

                    button {
                        padding-left: 0.5rem;
                        padding-right: 0.5rem;
                    }
                }
            }
        }
    }

    .tab-content {
        width: 100%;
    }
}

.navbar-brand {
    img {
        height: 1rem;

        @include media-breakpoint-up(sm) {
            height: 2rem;
            padding-left: 1.5rem;
        }
    }
}

.checkout-menu-bar {
    position: sticky;
    top: 2.875rem;
    z-index: 1024;

    @include media-breakpoint-up(md) {
        top: 6.75rem;
    }
}