.breadcrumb {
    color: $primary-dark;
    text-transform: uppercase;
    margin-bottom: 0;
    padding: 1rem 0;

    p {
        margin-bottom: 0;
    }

    a {
        color: $primary-dark;

        &:hover {
            color: $primary-dark;
            text-decoration: underline;
            text-underline-offset: 2px;
        }

        &.active {
            font-weight: $font-bold;
        }
    }
}