.table {
    text-align: center;
    font-size: .875rem;
    color: $primary-dark;

    th {
        font-weight: $font-light;
    }
}

.table-striped {
    tbody {
        tr:nth-of-type(odd) {
            th {
                color: $primary-dark;
            }

            td {
                color: $primary-dark;
            }
        }
    }
}

#orderTable, #orderHistoryTable {
    .table-title {
        text-align: center;
        background-color: $primary-dark;
        color: $white;

        th {
            font-weight: $font-regular;
            border: none;
        }
    }

    .table-labels {
        background-color: $secondary-alt;
        color: $white;

        th {
            width: 10%;

            &.sm-wider{
                @include media-breakpoint-down(md){
                    width: 75%;
                }
            }
        }
    }

    tr:nth-child(odd) td {
        background-color: $primary-lighter;
    }

    tr:nth-child(even) td {
        background-color: $primary-light;
    }

    .qty {
        input {
            max-width: 5rem;
            text-align: center;
            margin: 0 auto;
            background-color: $white;
        }
    }

    td, th {
        vertical-align: middle;
    }

    .sku-size {
        min-width: 2rem;
    }

    .unit-price {
        min-width: 3.75rem;
    }

    tr {
        &.bg-none {
            td {
                background: transparent;
                border-bottom: 0;

                &.bg-primary-dark {
                    background: $primary-dark !important;
                    color: $white;
                }
            }
        }
    }
}

#mobileOrderTable, #mobileOrderHistoryTable {
    .table-title {
        text-align: start;
        background-color: $primary-dark;
        color: $white;

        th {
            border: none;
        }
    }

    td, th {
        vertical-align: middle;
    }

    .hiddenRow {
        padding: 0 !important;
    }

    .table-labels {
        background-color: $secondary-alt;
        color: $white;
    }

    tr:nth-child(4n-3) td {
        background-color: $primary-lighter;
    }

    tr:nth-child(4n-1) td {
        background-color: $primary-light;
    }

    .qty {
        input {
            max-width: 5rem;
            text-align: center;
            margin: 0 auto;
            background-color: $white;
        }
    }

    .btn-icon {
        padding: 0;

        svg {
            .stroke-color {
                fill: $primary-dark;
            }

            .fill-color {
                fill: transparent;
            }
        }

        &[aria-expanded="true"] {
            svg {
                transform: rotate(90deg);

                .stroke-color {
                    fill: $white;
                }

                .fill-color {
                    fill: $primary-dark;
                }
            }
        }
    }

    .mobile-more-details__table {
        padding: 0;

        tr:nth-child(even) {
            background-color: $primary-lighter !important;

            td {
                background-color: inherit;
            }
        }

        tr:nth-child(odd) {
            background-color: $primary-light !important;

            td {
                background-color: inherit;
            }
        }
    }

    .table-row__hidden {
        display: none;
    }
}

.account-table, #fastTrackDeliveryOptions, #orderSummaryDetails {
    p {
        margin-bottom: 0;
    }

    h3 {
        margin-bottom: 0;
    }

    .table-row {
        color: $primary-dark;
        background-color: $primary-light;
        padding-top: 1rem;
        padding-bottom: 1rem;
        align-items: center;

        .permission-options {
            padding-top: 1rem;
            padding-bottom: 1rem;
            display: flex;
            justify-content: start;

            @include media-breakpoint-down(md) {
                line-height: 3em;
                height: 6em;
            }
        }

        input {
            margin-bottom: 0;
        }

        &.address-row {
            &:nth-of-type(odd) {
                color: red;
            }

            &:nth-of-type(even) {
                color: green;
            }
        }

        &.row-light {
            background-color: $primary-lighter;
        }
    }

    .table-row-title {
        background-color: $primary-dark;
        color: $white;
    }

    .table-row-alt-title {
        background-color: $primary-light;
        color: $black;
    }

    .table-row-subtitle {
        background-color: $secondary-alt;
        color: $white;
    }

    .table-row-button {
        background-color: $primary-alt;

        button {
            padding-top: 1rem;
            padding-bottom: 1rem;
            width: 100%;
        }
    }

    .table-row-button-dark {
        background-color: $primary-dark;

        button {
            padding-top: 1rem;
            padding-bottom: 1rem;
            width: 100%;
        }
    }

    .instructions {
        background-color: rgba($secondary-alt, .2);
    }

    input {
        text-align: center;
        box-sizing: border-box;
        border: none;

        &:focus {
            outline: none;
        }
    }
}

#basketTable, #mobileBasketTable, #previouslyOrderedItemsTable, #confirmedBasketTable, #mobileConfirmedBasketTable {
    @include media-breakpoint-down(md) {
        font-size: 0.725rem;

        th, td {
            padding: 0.3rem;
        }
    }

    thead {
        background-color: $secondary-alt;
        color: $white;
    }

    tbody > tr > * {
        background-color: $primary-light !important;
    }

    tbody > tr:nth-of-type(odd) > * {
        background-color: $primary-lighter !important;
    }

    .icon {
        stroke: $primary-dark;
        fill: none;

        &:hover {
            stroke: $primary-alt;
        }
    }
}

#basketTotalTable, #standardDeliveryOptions, #orderSummaryTable {
    .table-col-sub {
        background-color: $secondary-alt;
        color: $primary-dark;
    }

    .table-col-title {
        background-color: $primary-dark;
        color: $white;
    }

    p {
        margin-bottom: 0;
    }

    .basket-total-row {
        font-weight: bold;
    }
}

.no-stock {
    background-color: rgba($red, 0.2);
    border: 2px solid $red;

    p {
        margin-bottom: 0;
    }
}

#mobileBasketTable, #mobileConfirmedBasketTable{
    @include media-breakpoint-down(md){
        font-size: 0.55rem;
    }

    th,td{
        padding: 0.3rem;
    }
}

#quickOrderOrderTable {
    thead {
        background-color: $secondary-alt;
        color: $white;
    }
}

.alternate-row:nth-child(odd) {
    background-color: $primary-lighter;
}

.alternate-row:nth-child(even) {
    background-color: $primary-light;
}

.td-quantity {
    width: 5rem;
}

.td-price {
    width: 7rem;
}

.td-delete {
    width: 5rem;
}

.rte {
    table {
        width: 100% !important;
        max-width: 100%;

        td {
            border-width: 3px;
            border-color: $black;
        }
    }
}