.modal-open {
    padding-right: 0px !important;
}

.modal-content {
    border-radius: 0;
}
 
.modal-header {
    border-bottom: none;
}

.modal-title {
    font-size: 1.125rem;
    color: $primary-dark;
}

.modal-footer{
    border-top: none;
}

.input-group-text {
    border: none;
    background: none;
    color: $primary-dark;
    font-size: .875rem;
}

.form-control {
    border: none;
    border-bottom: 1px solid $primary-dark;
    border-radius:0;
}

#newsletterModal {
    @include media-breakpoint-down(sm) {
        [type=checkbox]:checked + label:after, [type=checkbox]:not(:checked) + label:after {
            font-size: 2em;
        }
    }
}

#termsConditionsSection {
    //scollbar
    /* width */
    &::-webkit-scrollbar {
       width: 10px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        background: $primary-light;
        margin: 0 1rem 0 1rem;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $primary-dark;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $primary-alt;
    }
}


#favouritesModal {
    .favouriteBoard-add {
        height: 40vh;
        overflow-y: auto;
        overflow-x: hidden;
        //scollbar
        /* width */
        &::-webkit-scrollbar {
            width: 10px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
            background: $primary-light;
            margin: 0 1rem 0 1rem;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $primary-dark;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $primary-alt;
        }

        @include media-breakpoint-down(sm) {
            height: 60vh;
        }

        label {
            font-size: 1rem;
            padding-left: 4rem;
            font-weight: 500;

            @include media-breakpoint-down(md) {
                font-size: 1rem;
            }
        }
    }
}

.loading {
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}