header, footer {
.form-control {
        background-color: transparent;
        border-radius: 0;
        border: none;
        color: $black;
        padding: .375rem .75rem .375rem 0;
    }

    input::placeholder {
        color: $black !important;
        text-transform: uppercase !important;
        opacity: 1;
    }

    .input-group-text {
        border-radius: 0;
        border: none;
        background-color: transparent;
        color: $black;
        font-weight: $font-regular;
    }    
}

#orderTable {
    input {
        border-radius: 0;
        padding: 0;
        border: none;
        font-size: .875rem;

        &:focus {
            background-color: rgba($secondary-alt, .4) !important;
        }
    }
}


[type="checkbox"], [type="radio"] {
    position: absolute;
    left: 0;
    opacity: 0;
}

[type="checkbox"] + label, [type="radio"] + label {
    position: relative;
    line-height: 2.2;
    padding-left: 2.5rem;
    font-size: .875rem;
    cursor: pointer;
    width: 100%;

    @include media-breakpoint-down(sm) {
        padding-left: 3em;
        line-height: 1.6;
    }
}

/* checkbox aspect */
[type="checkbox"] + label:before, [type="radio"] + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 2rem;
    height: 2rem;
    background: $white;

    @include media-breakpoint-down(md) {
        width: 1.5rem;
        height: 1.5rem;
    }
}

input[type="checkbox"]:disabled + label:before {
    background: rgba($primary-light, .4);
}

/* checked mark aspect */
[type="checkbox"]:checked + label:before, [type="radio"]:checked + label:before {
    background-image: url('../images/icons/tick-pink.svg');
}

label {
    font-size: .875rem;
    color: $primary-dark;
}

.form-control:focus {
    box-shadow: none;
    background-color: transparent;
    color: $primary-dark;
}

.form-select {
    border-radius: 0;
    font-size: .875rem;
    color: $primary-dark;
}

.form-select:focus {
    box-shadow: none;
}

.custom-select-dropdown {

    button {
        background-color: $primary-light;
        color: $primary-dark;
        border: 1px solid $secondary-dark;

        &.dropdown-toggle::after {
            margin-left: 1rem;
        }

        &:hover, &:active, &.show, &:first-child:active {
            background-color: $primary-light;
            color: $primary-dark;
            border: 1px solid $secondary-dark;
        }

        .form-select:focus {
            border: 1px solid $secondary-dark;
        }
    }

    .custom-select {
        width: 90%;
        display: inline-flex;
        justify-content: center;
        text-overflow: ellipsis;
        text-wrap: nowrap;

        p {
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0;
        }
    }

    .dropdown-menu {
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0;
        border-radius: 0;
        border: 1px solid $secondary-dark;
        margin-top: -2px !important;

        li {

            &:last-child {
                border-bottom: 0;
            }

            a {
                text-wrap: pretty;
                text-align: center;
                display: block;
                font-weight: 400;
                transition: none;
                padding-bottom: 0.25rem;

                &:hover {
                    background-color: rgb(30,144,255);
                    color: $white;
                    text-decoration: none;
                    transition: none;
                }
            }
        }
    }
}

.form-check {
    text-align: left;
}

#checkoutOptionSelect{
    background-color:$primary-light;
    font-weight:bold;
}

#checkout-delivery-details {
    select {
        background-color: $primary-light;
    }
}


input[type=search]::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
}

input[type='radio'] {
    box-sizing: border-box;
    border-radius: 0 !important;
    appearance: none;
    background: white;
    outline: 2px solid $primary-alt;
    border: none;
    width: 16px;
    height: 16px;

   
}

input[type='radio']:focus {
    box-shadow: 0 0 8px rgba(($primary-alt), 0.2);
}
input[type='radio']:active {
    background: $primary-alt;
    outline: 2px solid $primary-alt;
    
}

input[type='radio']:checked {
    background: $primary-alt;
    background-image:none !important;
    outline: 2px solid $primary-alt;
    accent-color: $primary-alt;
}

#returnsFormLine{
    input{
        width:100%;
    }
}

main {
    input[type='text'], input[type='password'], input[type='email'], input[type='number'], input[type='url'], input[type='tel'], input[type='date'], textarea {
        box-sizing: border-box;
        border: 0;
        margin-bottom: 1rem;
        width: 80%;
        padding: 5px;
        font-size: .875rem;
        color: $primary-dark;

        &:focus {
            outline: none;
            background-color: rgba($secondary-alt, .2);
        }

        @include media-breakpoint-down(md) {
            font-size: 0.75rem;
        }
    }
}

#registerForm, #enquiryForm {
    input {
        text-align: left;
    }

    option {
        text-align: left;
    }

    ::placeholder {
        text-align: left;
    }
}

.wrapper-dropdown {
    position: relative;
    width: 100%;
    background: $white;
    color: $black;
    outline: none;
    cursor: pointer;
    font-size:1rem;
}

.wrapper-dropdown > span {
    width: 100%;
    display: block;
    padding: 10px;
}

    .wrapper-dropdown > span > span {
        padding: 0 12px;
        margin-right: 5px;
    }

    .wrapper-dropdown > span:after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        right: 16px;
        top: calc(50% + 4px);
        margin-top: -6px;
        border-width: 6px 6px 0 6px;
        border-style: solid;
        border-color: $primary-alt transparent;
    }

    .wrapper-dropdown .colour-dropdown {
        position: absolute;
        z-index: 10;
        top: 100%;
        left: 0;
        right: 0;
        background: $primary-light;
        font-weight: normal;
        list-style: none;
        padding-left: 0;
        margin: 0;
        border-top: 0;
        max-height: 21.5rem;
        overflow-y: scroll;
    }

.wrapper-dropdown .colour-dropdown li {
    display: block;
    text-decoration: none;
    color: $black;
    padding: .375rem .75rem;
    cursor: pointer;
    font-size: .875rem;
}

.wrapper-dropdown .colour-dropdown li > span {
    padding: 0 12px;
    margin-right: 5px;
}

.wrapper-dropdown .colour-dropdown li:hover {
    cursor: pointer;
}


.form-control{
    &.is-invalid{
        background-color:$white;
        background-image: none;
    }
}


.invalid-feedback {
    font-size: 0.7rem;
    margin-top: -.75rem;
    padding-bottom: .75rem;
}

//remove arrows from number input

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
    border: none;
}







