.btn {
    border-radius: 0;
    border: none;
    text-transform: uppercase;
    font-weight: $font-bold;
}

.btn-primary-alt {
    background-color: $primary-alt;
    color: $white;

    &:hover, &:active {
        background-color: $primary-dark !important;
        color: $white !important;
        text-decoration: underline;
        text-underline-offset: 2px;
    }

    &:disabled {
        background-color: $primary-light;
    }

    &.collapse-button {
        &:hover, &:active {
            background-color: $primary-alt !important;
        }
    }
}

.btn-primary-dark {
    background-color: $primary-dark;
    color: $white;

    &:hover {
        background-color: $primary-alt;
        color: $white;
        text-decoration: underline;
        text-underline-offset: 2px;
    }
}

.btn-grey {
    background-color: rgba(($grey),0.4);    
    color: $white;

    &:hover {
        background-color: $primary-alt;
        color: $white;
       
        text-underline-offset: 2px;
    }
}

.btn-icon {
    border-radius: 0;
    border: none;
    text-transform: uppercase;
    background-color: transparent;
    color: $white;

    p {
        font-size: 0.65rem;
        line-height: 1.4;

        @include media-breakpoint-down(xxl) {
            font-size: 0.55rem;
        }
    }
    &.group{
        &:hover{
            .fill-dark{
                fill: $primary-alt;
            }
        }
    }
}

.social-media-links {
    svg {
        fill: $white;
    }
}

.btn-dropdown {
    &[aria-expanded="false"] {
        .expand-icon {
            transform: rotate(45deg);
        }
    }
}


.btn-favourite {
    z-index: 1;

    &.clicked {
        .favourites-icon {
            .fill-color {
                fill: $primary-alt;
            }

            .stroke-color {
                stroke: $primary-alt;
                stroke-width: 3px;
            }
        }
    }    
}

.btn-reset {
    text-align: center;
    cursor: pointer;
    background-color: $primary-alt;
    color: $white;
    padding-top: .5rem !important;

    &:hover {
        background-color: $primary-dark;
    }
}

#favouriteProductList {
    @include media-breakpoint-down(md) {
        button {
            font-size: 0.65rem;
        }
    }
}

.btn-edit, .btn-delete {
    svg {
        height: 2rem;
        width: 2rem;

        path {
            fill: $primary-alt;
        }
    }

    &:hover {
        svg {
            path {
                fill: $primary-dark;
            }
        }
    }
}